import { defineStore } from 'pinia'
import {
  useContactsStore,
  useDomainThemeStore,
  useOrganizationStore,
  useQiankunStore,
  useSolutionsStore,
  useUserInfoStore,
} from '.'

export const useResetStore = defineStore('resetPinia', {
  // arrow function recommended for full type inference
  state: () => {
    return {}
  },
  actions: {
    reset() {
      useUserInfoStore().$reset()
      useDomainThemeStore().$reset()
      useOrganizationStore().$reset()
      useSolutionsStore().$reset()
      useContactsStore().$reset()
      useQiankunStore().$reset()
    },
  },
})
