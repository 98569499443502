import request from '.'
import type { TAnyRecord } from '.'

/**
 * 查询国家列表
 * @param id id
 * @param name 全称
 * @param cname 中文全称
 * @param code 代码
 * @param parentId 父id
 * @param pageNum 当前页码，默认情况下为第一页，页码从1开始
 * @param pageSize 每页的记录数，默认情况下每页十条记录
 * @param paged 是否分页 默认为true
 * @param orderStr 格式为：{name1:direction,name2:direction,...}，例如name:desc,age:asc,createTime
 * @returns {Promise<any>}
 */
export const getCountries = ({
  id,
  name,
  cname,
  code,
  parentId,
  pageNum,
  pageSize,
  paged = false,
  orderStr,
}: TAnyRecord) => {
  return request({
    url: `/core/area/countries`,
    method: 'GET',
    params: {
      id,
      name,
      cname,
      code,
      parentId,
      pageNum,
      pageSize,
      paged,
      orderStr,
    },
  })
}

/**
 * 查询省 州列表
 * @param id id
 * @param name 全称
 * @param cname 中文全称
 * @param code 代码
 * @param parentId 父id
 * @param pageNum 当前页码，默认情况下为第一页，页码从1开始
 * @param pageSize 每页的记录数，默认情况下每页十条记录
 * @param paged 是否分页 默认为true
 * @param orderStr 格式为：{name1:direction,name2:direction,...}，例如name:desc,age:asc,createTime
 * @returns {Promise<any>}
 */
export const getStates = ({
  id,
  name,
  cname,
  code,
  parentId,
  pageNum,
  pageSize,
  paged = false,
  orderStr,
}: TAnyRecord) => {
  return request({
    url: `/core/area/states`,
    method: 'GET',
    params: {
      id,
      name,
      cname,
      code,
      parentId,
      pageNum,
      pageSize,
      paged,
      orderStr,
    },
  })
}

/**
 * 查询城市列表
 * @param id id
 * @param name 全称
 * @param cname 中文全称
 * @param code 代码
 * @param parentId 父id
 * @param pageNum 当前页码，默认情况下为第一页，页码从1开始
 * @param pageSize 每页的记录数，默认情况下每页十条记录
 * @param paged 是否分页 默认为true
 * @param orderStr 格式为：{name1:direction,name2:direction,...}，例如name:desc,age:asc,createTime
 * @returns {Promise<any>}
 */
export const getCities = ({
  id,
  name,
  cname,
  code,
  parentId,
  pageNum,
  pageSize,
  paged = false,
  orderStr,
}: TAnyRecord) => {
  return request({
    url: `/core/area/cities`,
    method: 'GET',
    params: {
      id,
      name,
      cname,
      code,
      parentId,
      pageNum,
      pageSize,
      paged,
      orderStr,
    },
  })
}

/**
 * 查询区列表
 * @param id id
 * @param name 全称
 * @param cname 中文全称
 * @param code 代码
 * @param parentId 父id
 * @param pageNum 当前页码，默认情况下为第一页，页码从1开始
 * @param pageSize 每页的记录数，默认情况下每页十条记录
 * @param paged 是否分页 默认为true
 * @param orderStr 格式为：{name1:direction,name2:direction,...}，例如name:desc,age:asc,createTime
 * @returns {Promise<any>}
 */
export const getRegions = ({
  id,
  name,
  cname,
  code,
  parentId,
  pageNum,
  pageSize,
  paged = false,
  orderStr,
}: TAnyRecord) => {
  return request({
    url: `/core/area/regions`,
    method: 'GET',
    params: {
      id,
      name,
      cname,
      code,
      parentId,
      pageNum,
      pageSize,
      paged,
      orderStr,
    },
  })
}
