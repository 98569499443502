import { RouteRecordRaw } from 'vue-router'

export const baseRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/components/layout/index.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/base/home/index.vue'),
        meta: {
          showTabBar: true,
          title: '首页',
        },
      },
      {
        path: 'workbench',
        name: 'workbench',
        component: () => import('@/views/base/workbench/index.vue'),
        meta: {
          showTabBar: true,
          title: '工作台',
        },
      },
      {
        path: 'notification',
        name: 'notification',
        component: () => import('@/views/base/notification/index.vue'),
        meta: {
          showTabBar: true,
          title: '消息通知',
        },
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: () => import('@/views/base/contacts/index.vue'),
        meta: {
          showTabBar: false,
          title: '联系人',
        },
      },
      // 我的页面
      {
        path: 'me',
        name: 'me',
        component: () => import('@/views/base/me/index.vue'),
        meta: {
          title: '我的',
          showTabBar: true,
        },
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/base/login/index.vue'),
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/base/profile/index.vue'),
        meta: {
          title: '个人资料',
        },
      },
      {
        path: '/organization',
        name: 'organization',
        component: () => import('@/views/base/organization/index.vue'),
        meta: {
          title: '切换组织',
        },
      },
      // {
      //   path: '/switchSolutions',
      //   name: 'switchSolutions',
      //   component: () => import('@/views/base/switchSolutions/index.vue'),
      //   meta: {
      //     title: '切换解决方案',
      //   },
      // },
      {
        path: '/contactsList',
        name: 'contactsList',
        component: () => import('@/views/base/contacts/contactsList.vue'),
        meta: {
          title: '联系人',
          showTabBar: true,
        },
      },
    ],
  },
]
