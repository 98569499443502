export interface ITheme {
  color: string
  themeName: string
  key: string
}

export const themes: ITheme[] = [
  {
    themeName: 'red',
    color: '#ff2445',
    key: 'mb-red',
  },
  {
    themeName: 'black',
    color: '#000',
    key: 'mb-black',
  },
  {
    themeName: 'blue',
    color: '#00f',
    key: 'mb-blue',
  },
  {
    themeName: 'green',
    color: '#0CC2B7',
    key: 'mb-green',
  },
  {
    themeName: 'el-blue',
    color: '#409EFF',
    key: 'mb-el-blue',
  },
  {
    themeName: 'orange',
    color: '#FF9670',
    key: 'mb-orange',
  },
  {
    themeName: 'blossom',
    color: '#FF889F',
    key: 'mb-blossom',
  },
]
