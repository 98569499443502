import { getEnterpriseTree } from '@/api/contacts'
import { getToken } from '@/utils/localStorage/useLocalStorage'
import { defineStore } from 'pinia'
import { useUserInfoStore } from './userInfo'

export interface IEnterprise {
  id: number
  name: string
  type: string
  rooted: boolean
  spread: boolean
  children?: IEnterprise[]
  parent: IEnterprise | null
  height: number
}

export interface IContacts {
  currentEnterprise: number
  enterprises: IEnterprise[]
}

const recursive = (
  enterprises: IEnterprise[],
  parent: IEnterprise | null = null
) => {
  for (const iterator of enterprises) {
    iterator.spread = true
    iterator.parent = parent
    iterator.height = 0
    if (iterator.children) {
      recursive(iterator.children, iterator)
    }
  }
}

export const useContactsStore = defineStore('contacts', {
  // arrow function recommended for full type inference
  state: (): IContacts => {
    return {
      currentEnterprise: useUserInfoStore().currentOrganizationId,
      enterprises: [],
    }
  },
  actions: {
    async init() {
      return getEnterpriseTree().then((res) => {
        const data = res.data as IEnterprise[]
        recursive(data)
        this.$patch({
          currentEnterprise: this.currentEnterprise,
          enterprises: data,
        })
      })
    },
    updateCurrentEnterprise(id: number) {
      this.currentEnterprise = id
    },
  },
  persist: false,
})
