export const loadMap = () => {
  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDjlUXkZkPZtuGiORInLeu5_zDzqa5P29Y&callback=initGoogleMap&libraries=places&v=weekly`
  // script.defer = true
  script.async = true
  document.body.appendChild(script)
}

const initGoogleMap = () => {
  console.log('initGoogleMap')
}

window.initGoogleMap = initGoogleMap
