import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import { Locale, showFailToast } from 'vant'
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'
import axios from 'axios'
import _ZHCN from './lang/zh-CN.json'
import _en from './lang/en.json'

// Locale.use('en-US', enUS)

const DETAIL_LOCALE = localStorage.getItem('language') || 'en'
export const SUPPORT_LOCALES = ['en', 'zh-CN']

const i18n = setupI18n({ locale: DETAIL_LOCALE })
// if (process.env.isVite) {
loadLocaleMessages(DETAIL_LOCALE)
// i18n.global.setLocaleMessage('zh-CN', _ZHCN)
// setI18nLanguage('zh-CN')
// }

function setupI18n(options = { locale: 'en' }) {
  // @ts-ignore
  const i18n = createI18n({
    ...options,
    fallbackLocale: 'zh-CN',
    messages: {
      'zh-CN': _ZHCN,
      en: _en,
    },
  })
  // setI18nLanguage(options.locale)
  return i18n
}

function setI18nLanguage(locale: string) {
  if (i18n.mode === 'legacy') {
    // @ts-ignore
    i18n.global.locale = locale
  } else {
    // @ts-ignore
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html')?.setAttribute('lang', locale)
}

export async function loadLocaleMessages(locale: string) {
  locale = locale.replace('_', '-')
  let messages: string = ''
  if (locale === 'en') {
    Locale.use('en-US', enUS)
  } else {
    Locale.use('zh-CN', zhCN)
  }
  // 请求oss
  await axios({
    url: `https://${import.meta.env.VITE_APP_BUCKET}.cos.${
      import.meta.env.VITE_APP_REGION
    }.myqcloud.com/basic-bm-webapp/${
      import.meta.env.VITE_NODE_ENV
    }/${locale}.json?t=${Date.now()}`,
  })
    .then((res) => {
      messages = res.data
    })
    .catch(() => {
      // showFailToast(`${locale} 配置文件加载失败`)
      messages = locale === 'en' ? _en : (_ZHCN as any)
    })
  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages as any)
  setI18nLanguage(locale)

  return nextTick()
}

export default i18n

const t = i18n.global.t.bind(i18n.global)
export { t, i18n }
