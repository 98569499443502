import { TAnyRecord } from '@/api'

export interface IChangeTheme {
  name: string
  color: string
}

export const MChangeTheme = (
  themes: IChangeTheme[],
  domainKey: string = 'mb-red'
) => {
  const root = document.querySelector<HTMLElement>(':root')
  if (root) {
    themes.forEach((theme) => {
      root.style.setProperty(theme.name, theme.color)
    })
    const d = localStorage.getItem('domainTheme')
    if (d) {
      const domainTheme = JSON.parse(d) as TAnyRecord
      domainTheme.theme = domainKey
      localStorage.setItem('domainTheme', JSON.stringify(domainTheme))
    }
  }
}
