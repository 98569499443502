import type { App } from 'vue'
import router from './router'
import installUi from './ui'
import pinia from './pinia'
import { i18n } from './locales/setup'

export default async (app: App) => {
  app.use(router)
  app.use(pinia)
  app.use(i18n)
  installUi(app)
}
