import { getToken } from '@/utils/localStorage/useLocalStorage'
import request from '.'
import type { TAnyRecord } from '.'
import { AxiosResponse } from 'axios'

export const getCountryCode = () => {
  return request({
    url: '/core/area/cc',
    needToken: false,
  })
}

// 获取当前账号信息
export const getCurrentAccount = <T>() => {
  return request<any, AxiosResponse<T>>({
    url: '/core/account/current',
  })
}

// 域名主题
export const getDomainTheme = (domain: string | null) => {
  return request({
    url: '/core/host_theme/host',
    needToken: false,
    params: {
      domain,
    },
  })
}

// 更新账号信息
export const updateAccount = ({
  avatar,
  name,
  gender,
  birthday,
  introduction,
  tags,
}: TAnyRecord) => {
  return request({
    url: '/core/account/personal',
    method: 'put',
    data: {
      avatar,
      name,
      gender,
      birthday,
      introduction,
      tags,
    },
  })
}

// 更新账号id
export const updateLoginId = (id: string) => {
  return request({
    url: '/core/account/code',
    method: 'PATCH',
    data: { content: id },
  })
}

// 修改手机或邮箱
export const changeAccount = ({
  type,
  currentPassword,
  iso_code,
  cc,
  newStubKey,
  captcha,
}: TAnyRecord<{ type: 'TELEPHONE' | 'EMAIL' }>) => {
  return request({
    url: '/core/account',
    method: 'put',
    data: {
      type,
      currentPassword,
      iso_code,
      cc,
      newStubKey,
      captcha,
    },
  })
}

// 修改密码
export const changePassword = ({
  currentPassword,
  password,
  repeatPassword,
}: TAnyRecord) => {
  return request({
    url: '/core/account/password',
    method: 'put',
    data: {
      currentPassword,
      password,
      repeatPassword,
    },
  })
}

// 忘记密码
export const forgetPassword = ({
  stubKey,
  captcha,
  password,
  repeatPassword,
}: TAnyRecord) => {
  return request({
    url: '/core/account/password/forget',
    method: 'post',
    data: {
      stubKey,
      captcha,
      password,
      repeatPassword,
    },
  })
}

/**
 * 切换国际化
 * @returns {AxiosPromise}
 */
export function switchI18n(lang: string = 'zh-CN') {
  return request({
    url: `/core/auth/language`,
    method: 'put',
    headers: {
      'Accept-Language': lang,
    },
  })
}

/**
 * 转B端登录
 * @param clientId 客户端Id
 * @param device 客户端类型 {"PC":"电脑端","MOBILE":"移动端"}
 * @returns {Promise<any>}
 */
export const postExchange = ({ clientId, device }: any) => {
  return request({
    url: `/core/auth/exchange`,
    method: 'POST',
    data: {
      clientId,
      device,
    },
  })
}
