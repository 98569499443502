import { getToken } from '@/utils/localStorage/useLocalStorage'
import axios from 'axios'
import router from '@/router'
import { showToast, closeToast } from 'vant'
import logout from '@/utils/logout'

export type TAnyRecord<T = Record<string, any>> = {
  [key in keyof T]: T[key]
} & {
  [key: string]: any
}

export const useLoading = () => {
  let number = 0

  const getLoading = () => {
    showToast({
      type: 'loading',
      duration: 0,
    })
  }

  const closeLoading = () => {
    closeToast()
  }

  return {
    upNumber: () => {
      if (number === 0) {
        getLoading()
      }
      number += 1
    },
    downNumber: () => {
      number -= 1
      if (number === 0) {
        closeLoading()
      }
    },
  }
}

const { upNumber, downNumber } = useLoading()

const request = axios.create({
  baseURL: import.meta.env.VITE_BASE_URI,
  needToken: true,
  originResponse: false,
  timeout: 1000 * 60,
})

request.interceptors.request.use((config) => {
  upNumber()
  const token = getToken()
  // TODO: 可以加个白名单不需要token就可以请求
  if (!token && config.needToken) {
    router.replace('/login')
    return Promise.reject({ response: { data: { message: 'not token' } } })
  }
  // if (!config.headers) {
  //   config.headers = {}
  // }
  if (token) {
    config.headers['Authorization'] = token as string
  }
  return config
})

request.interceptors.response.use(
  (res) => {
    downNumber()
    const config = res.config
    if (config.originResponse) {
      return res
    }
    return res.data
  },
  (err) => {
    downNumber()
    const response = err.response
    console.error(response)
    if (response.status === 401) {
      showToast({
        type: 'text',
        message: response.statusText,
        onClose: () => {
          logout(false)
        },
      })
    } else {
      showToast(response.data.message || response.data.error)
    }
    return Promise.reject(err)
  }
)

export default request
