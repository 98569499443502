import { defineStore } from 'pinia'
import { i18n, loadLocaleMessages } from '@/locales/setup'
import { switchI18n } from '@/api/base'
import { useDomainThemeStore } from './domainTheme'
import { getToken } from '@/utils/localStorage/useLocalStorage'

export const useLocalesStore = defineStore('locales', {
  // arrow function recommended for full type inference
  state: () => {
    return {
      currentLanguage: useDomainThemeStore().language || 'en',
      languages: [
        {
          label: 'English',
          value: 'en',
        },
        {
          label: '中文',
          value: 'zh-CN',
        },
      ],
    }
  },
  getters: {
    langLabel: (state) => {
      return state.languages.find(
        (item) => item.value === state.currentLanguage
      )?.label
    },
  },
  actions: {
    async updateLanguage(lang: string) {
      lang = lang.replace('_', '-') // 兼容旧数据
      if (this.languages.map((item) => item.value).includes(lang)) {
        const { locale } = i18n.global
        if (getToken()) {
          return switchI18n(lang).then(() => {
            this.currentLanguage = lang
            // @ts-ignore
            locale.value = lang
            return loadLocaleMessages(lang)
          })
        } else {
          this.currentLanguage = lang
          // @ts-ignore
          locale.value = lang
          return loadLocaleMessages(lang)
        }
      } else {
        console.error('不支持该语言')
        return Promise.reject()
      }
    },
  },
})
