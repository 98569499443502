import { getSolutionsList } from '@/api/solutions'
import {
  setSolutions,
  getToken,
  getSolutions,
} from '@/utils/localStorage/useLocalStorage'
import { printTs } from '@/utils/printTs'
import { defineStore } from 'pinia'
import { useQiankunStore } from './qiankun'

export interface IApplicationList {
  id: number
  appCode: string
  appSn: string
  name: string
  type: string
  appkey: string
  appSecret: string
  remark: string
  enable: boolean
  applicationSnId: number
  organizationName: null
  orgCode: null
}

export interface IProjectList {
  id: number
  name: string
  applicationId: number
  appCode: string
  type: string
  enable: boolean
  remark: string
  organizationName: unknown
  orgCode: unknown
  url: string
}

export interface IResourceList {
  id: number
  name: string
  displayName: string
  icon: string
  iconCode: unknown
  parentId: unknown
  path: unknown
  type: string
  code: string
  uri: unknown
  config: string
  sort: number
  selected: unknown
  display: boolean
  enable: boolean
  link: boolean
  linkUrl: string
  remark: unknown
  applicationId: number
  preview: unknown
  previewed: unknown
  logicTag: string
  projectId: number
  language: unknown
  children: IResourceList[]
}

export interface ISolutionMenuVOList {
  id: number
  name: string
  displayName: string
  icon: string
  enable: boolean
  sort: number
  type: string
  level: number
  remark: string
  resourceList: IResourceList[]
  hiddenButtonList: unknown[]
}

export interface ISolutions {
  applicationList: IApplicationList[]
  projectList: IProjectList[]
  solutionMenuVOList: ISolutionMenuVOList[]
  currentSolutionId: number
}

export const useSolutionsStore = defineStore('solutions', {
  state: (): ISolutions => {
    return {
      applicationList: [],
      projectList: [],
      solutionMenuVOList: [],
      currentSolutionId: 0,
    }
  },
  getters: {
    menus: (state): ISolutionMenuVOList | undefined => {
      return state.solutionMenuVOList.find(
        (item) => item.id === state.currentSolutionId
      )
    },
  },
  actions: {
    async init() {
      return getSolutionsList<ISolutions>()
        .then((res) => {
          const data = res.data
          this.$patch(data as any)
          const id = this.solutionMenuVOList.find(
            (item) => item.id === this.currentSolutionId
          )?.id
          this.updateCurrentSolutionId(id)
          useQiankunStore().init()
        })
        .catch((e) => e)
    },
    updateCurrentSolutionId(id?: number) {
      if (!id) {
        this.currentSolutionId = this.solutionMenuVOList[0]?.id || 0
      } else {
        this.currentSolutionId = id
      }
    },
  },
})
