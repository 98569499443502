import pinia, { useFlutterStore } from '@/pinia'
import { basicInit } from '@/pinia/loop'
import { pubsub } from '@/utils/pubsub'

// flutter下发的全局变量
pubsub.subscribe('globalVariable', async (_, [data]: any) => {
  try {
    localStorage.setItem('platform', 'mybeauty-app')
    const flutterStore = useFlutterStore(pinia)
    flutterStore.globalVariable = data
    console.log(JSON.stringify(flutterStore.globalVariable), 'globalVariable')
    if (flutterStore.globalVariable.exchange) {
      const exchange = JSON.parse(flutterStore.globalVariable.exchange)
      Object.keys(exchange).forEach((key) => {
        localStorage.setItem(key, exchange[key])
      })
      try {
        await basicInit()
      } catch (error) {
        console.log(error)
      }
      pubsub.publish('initApp', () => {
        console.log('mybeauty app 打开')
      })
    }
  } catch (error) {
    console.log('====', error)
  }
})

// 监听flutter发布的通信
window.flutterToJs = (eventName: PubsubEventName, ...args: any) => {
  console.log(eventName, args)
  pubsub.publish(eventName, args)
  // setTimeout(() => pubsub.publish(eventName, args), 60)
}

// 发布flutter通信
pubsub.subscribe('jsToFlutter', (_, data: any) => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler('jsToFlutter', data)
  } else {
    window.jsToFlutter.postMessage(data)
  }
})
