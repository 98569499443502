import type { App } from 'vue'
// import { installVant } from './vant'
import './vant'
// import { installGlobalComponents } from './mb'
import MbComponents from '@/components'

const installUi = (app: App) => {
  // installVant(app)
  // installGlobalComponents(app)
  app.use(MbComponents)
}

export default installUi
