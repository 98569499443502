import _MbUploader from './src/index.vue'
import { withInstall } from '../with-install'

const MbUploader = withInstall(_MbUploader)
export default MbUploader
declare module 'vue' {
  export interface GlobalComponents {
    MbUploader: typeof MbUploader
  }
}
