import { getCurrentAccount } from '@/api/base'
import { defineStore } from 'pinia'
import { useContactsStore } from './contacts'

interface IUserInfo {
  language: string
  currentEmployeeId: number
  code: string
  avatar: string
  name: string
  gender: string
  birthday: number
  introduction: string
  tags: string[]
  currentOrganizationId: number
  telephone: string
  mail: string
  facebook?: string
  instagram?: string
}

export const useUserInfoStore = defineStore('userInfo', {
  state: (): IUserInfo => {
    return {
      code: '',
      avatar: '',
      name: '',
      gender: '',
      birthday: -1,
      introduction: '',
      tags: [],
      currentOrganizationId: -1,
      currentEmployeeId: -1,
      telephone: '',
      mail: '',
      facebook: '',
      instagram: '',
      language: '',
    }
  },
  actions: {
    async init() {
      return getCurrentAccount<IUserInfo>().then((res) => {
        const data = res.data
        this.$patch(data)
        useContactsStore().updateCurrentEnterprise(this.currentOrganizationId)
      })
    },
  },
})
