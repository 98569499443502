import { defineStore } from 'pinia'
import { useSolutionsStore } from './solutions'
import { loadMicroApp } from 'qiankun'
import type { LoadableApp } from 'qiankun'
import router from '@/router'
import type { MicroApp } from 'qiankun'
import { useLoading } from '@/utils/loading'
import { useFlutterStore } from './flutter'
import MqttTools from '@/mqtt'

type IAppInfo = {
  appCode: string
  name: string
  type: string
  url: string
}

interface IQiankunState {
  instance?: MicroApp
  timer: NodeJS.Timer | null
  apps: IAppInfo[]
}

const { upNumber, downNumber } = useLoading()

const localApps: IAppInfo[] = [
  {
    appCode: 'goodthinghub',
    name: 'goodthinghub',
    type: 'BM_SUBWEB',
    url: '//localhost:40002',
  },
]

export const useQiankunStore = defineStore('qiankun', {
  // arrow function recommended for full type inference
  state: (): IQiankunState => {
    return {
      instance: undefined,
      timer: null,
      apps: [],
    }
  },
  actions: {
    async init() {
      const isLocal = /^localhost|127.0.0.1|192.168.\d+.\d+/.test(
        location.hostname
      )
      if (isLocal) {
        localApps.map((item) => (item.url = `//${location.hostname}:40002`))
        this.$patch({
          apps: localApps,
        })
      } else {
        this.$patch({
          apps: useSolutionsStore().projectList,
        })
      }
    },
    loadMicroApp(appCode: string) {
      const app = this.apps.find((item) => item.appCode === appCode)
      if (app) {
        upNumber('loading...')
        const microApp: LoadableApp<Record<string, any>> = {
          name: app.name,
          entry: app.url,
          container: '#subapp',
          props: {
            activeRule: app.appCode,
            parentRouter: router,
            flutterProps: useFlutterStore().globalVariable,
            // mqtt
            mqtt: MqttTools.instance,
          },
        }
        this.instance = loadMicroApp(microApp, {
          // excludeAssetFilter: (assetUrl: string) => {
          //   console.log(assetUrl)
          //   console.log(assetUrl.includes('maps.googleapis.com'))
          //   // return !assetUrl.endsWith('.js')
          //   return assetUrl.includes('maps.googleapis.com')
          // },
        })
        // this.timer = setInterval(() => {
        //   if (this.instance?.getStatus() === 'MOUNTED') {
        //     clearInterval(this.timer as NodeJS.Timer)
        //     this.timer = null
        //     downNumber()
        //   }
        // }, 60)
        let id: number
        const s = () => {
          if (this.instance?.getStatus() === 'MOUNTED') {
            downNumber()
            cancelAnimationFrame(id)
          } else {
            requestAnimationFrame(s)
          }
        }
        id = requestAnimationFrame(s)
      } else {
        console.error(`没有找到子应用： ${appCode}`)
      }
    },
    unMount() {
      if (this.instance) {
        this.instance.unmount?.().then(() => {
          this.instance = undefined
        })
      }
    },
  },
})
