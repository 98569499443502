const parserJson = (val: any) => {
  if (!val) return val
  try {
    return JSON.parse(val)
  } catch (error) {
    return val
  }
}

export const setToken = (val: string) => localStorage.setItem('token', val)
export const getToken = (): string | null =>
  parserJson(localStorage.getItem('token'))

export const setOrganization = (val: any) =>
  localStorage.setItem('organization', val)
export const getOrganization = (): any =>
  parserJson(localStorage.getItem('organization'))

export const setSolutions = (val: any) => localStorage.setItem('solutions', val)
export const getSolutions = (): any =>
  parserJson(localStorage.getItem('solutions'))
