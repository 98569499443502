<script lang="ts">
export default {
  name: 'MbRefresh',
}
</script>

<script setup lang="ts" generic="T">
import { computed, Ref, ref, watch } from 'vue'
import { IPageInfo, IListPageData } from './types'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface IProps {
  model: IListPageData<T>
}

defineSlots<{
  default(props: { row: T; index: number }): any
}>()

const defaultPagesize = 10

const props = withDefaults(defineProps<IProps>(), {
  model: () => ({
    // pageNum: 1,
    // pageSize: 10,
    // totalPages: 0,
    // totalElements: 0,
    content: [],
  }),
})
const emit = defineEmits<{
  (e: 'r', pageInfo: IPageInfo): void
}>()

// 本地备份一份数据，使用该数据进行遍历，实现父组件无感使用
const data = ref<T[]>([]) as Ref<T[]>
const loading = ref(false)
const finished = ref(false)
const refreshing = ref(false)
const pageNum = computed(() => props.model.pageNum ?? 1)
const totalPages = computed(() => props.model.totalPages ?? 0)

watch(
  () => props.model,
  () => {
    finished.value = pageNum.value >= totalPages.value
    if (refreshing.value) {
      // data.value = props.model.content
      refreshDone()
    }
    if (loading.value) {
      if (props.model.content.length) {
        data.value = data.value.concat(props.model.content)
      } else {
        data.value = []
      }
      loadDone()
    }
    if (pageNum.value <= 1) {
      data.value = props.model.content
    }
  }
)

const loadDone = () => {
  loading.value = false
  // finished.value = pageNum.value >= totalPages.value
}

const refreshDone = () => {
  setTimeout(() => {
    refreshing.value = false
  }, 333)
}

const onLoad = () => {
  if (pageNum.value < totalPages.value) {
    emit('r', {
      pageNum: pageNum.value + 1,
      pageSize: props.model.pageSize ?? defaultPagesize,
      // totalPages: props.model.totalPages,
      // totalElements: props.model.totalElements,
    })
  } else {
    loadDone()
  }
}

// 下拉刷新
const onRefresh = () => {
  loading.value = false
  finished.value = false
  emit('r', {
    pageNum: 1,
    pageSize: props.model.pageSize ?? defaultPagesize,
    // totalPages: props.model.totalPages,
    // totalElements: props.model.totalElements,
  })
}

// 暴露全部data数据，情况很少，但是某些场景下确实需要
defineExpose({
  getData(cb: (data: T[]) => void) {
    cb?.call(undefined, data.value)
  },
})
</script>

<template>
  <div class="mb-refresh">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :immediate-check="false"
        :finished-text="t('1fe9009c96c23be837d9d52feda9c23e')"
        @load="onLoad">
        <template v-for="(item, index) in data" :key="index">
          <slot :row="item" :index="index"></slot>
        </template>
        <van-empty
          v-if="!data.length"
          :description="t('21efd88b67a39834582ad99aabb9dc60')"></van-empty>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
