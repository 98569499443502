// import { piniaLoop } from '@/pinia/loop'
import { getToken } from '@/utils/localStorage/useLocalStorage'
import logout from '@/utils/logout'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { baseRoutes } from './base'
import { useLoading } from '@/utils/loading'

const { upNumber, downNumber } = useLoading()

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/workbench',
  },
  ...baseRoutes,
  {
    path: '/theme',
    name: 'theme',
    component: () => import('@/views/theme/index.vue'),
    meta: {
      title: '切换主题',
    },
  },
  {
    path: '/basic_organizationManage_m',
    name: 'basic_organizationManage_m',
    component: () => import('@/views/organizationManage/index.vue'),
    meta: {
      title: '机构管理',
    },
  },
  {
    path: '/basic_esLog_m',
    name: 'basic_esLog_m',
    component: () => import('@/views/theme/index.vue'),
    meta: {
      title: '操作日志',
    },
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: () => import('@/views/protocol/index.vue'),
    meta: {
      title: '协议',
    },
  },
  // other routes
]

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior: () => {
    return {
      top: 0,
    }
  },
})

// 白名单
const whiteList = ['/login', '/protocol']
// 不需要loading的页面
const notLoadingRoute = ['middleware']

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || ''

  const token = getToken()
  if (!notLoadingRoute.includes(to.name as string)) {
    upNumber('page loading...')
  }

  if (!token && !whiteList.includes(to.path)) {
    await logout()
    return false
  }
  next()
})

router.afterEach(() => downNumber())

export default router
