import { getDomainTheme } from '@/api/base'
import { defineStore } from 'pinia'
import { MChangeTheme } from '@/views/theme/changeTheme'
import type { IChangeTheme } from '@/views/theme/changeTheme'
import { themes } from '@/views/theme/theme'

interface IDomainTheme {
  logo: string
  theme: string
  title: string
  region: string
  slogan: string
  favicon: string
  privacy: string
  language: string
  agreement: string
  copyright: string
  loginPost: string[]
  logoSmall: string
  iconjs: string
  iconcss: string
}

export const useDomainThemeStore = defineStore('domainTheme', {
  // arrow function recommended for full type inference
  state: (): IDomainTheme => {
    return {
      logo: '',
      theme: '',
      title: '',
      region: '',
      slogan: '',
      favicon: '',
      privacy: '',
      language: '',
      agreement: '',
      copyright: '',
      loginPost: [],
      logoSmall: '',
      iconcss: '',
      iconjs: '',
    }
  },
  actions: {
    async init() {
      const domain = /localhost:\d+|192.168.*:\d+/.test(location.host)
        ? 'bm_localhost'
        : null
      return getDomainTheme(domain).then((res) => {
        const data = JSON.parse(res.data.config) as IDomainTheme
        this.$patch(data)
        this.loadTheme()
      })
    },
    loadTheme() {
      const theme = themes.find((item) => item.key === this.theme)
      if (theme) {
        const theme2: IChangeTheme[] = [
          {
            name: '--primary-color',
            color: theme.color,
          },
          // {
          //   key: '--primary-color2',
          //   color: theme.color,
          // },
        ]
        MChangeTheme(theme2, theme.key)
      }

      const { iconcss, iconjs, favicon } = this
      if (iconcss) {
        if (!document.querySelector(`link[href='${iconcss}']`)) {
          const cssLink = document.createElement<'link'>('link')
          cssLink.rel = 'stylesheet'
          cssLink.href = iconcss
          document.head.appendChild(cssLink)
        }
      }
      if (iconjs) {
        if (!document.querySelector(`script[src='${iconjs}']`)) {
          const script = document.createElement<'script'>('script')
          script.src = iconjs
          document.body.appendChild(script)
        }
      }
      if (favicon) {
        const faviconEle =
          document.querySelector<HTMLLinkElement>(`link[rel*='icon']`)
        faviconEle && (faviconEle.href = favicon)
      }
    },
  },
})
