// 注册companies目录下的所有组件

import type { App } from 'vue'

import MbUploader from './MbUploader'
import MbNavBar from './MbNavBar'
import MbAddress from './MbAddress'
import MbRefresh from './MbRefresh'

const components = [MbUploader, MbNavBar, MbAddress, MbRefresh]

export default {
  install: (app: App, options?: Record<string, any>) => {
    components.forEach((component) => {
      // app.component(component.name, component)
      app.use(component)
    })
    console.log('%cglobal components register success', 'color: green;')
  },
}
