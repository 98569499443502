import { logoutEnd } from '@/api/login'
import MqttTools from '@/mqtt'
import { useDomainThemeStore } from '@/pinia'
// import { useResetStore } from '@/pinia'
import router from '@/router'
import { RouteLocationRaw } from 'vue-router'

const logout = (req: boolean = true, path: RouteLocationRaw = '/login') => {
  MqttTools.instance?.disconnect()
  if (req) {
    return logoutEnd().then(() => {
      // useResetStore().reset()
      localStorage.clear()
      useDomainThemeStore().init()
      router.replace(path)
    })
  } else {
    // useResetStore().reset()
    localStorage.clear()
    useDomainThemeStore().init()
    router.replace(path)
    return Promise.resolve()
  }
}

export default logout
