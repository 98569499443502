import type { App, Component } from 'vue'

// https://github.com/vant-ui/vant/issues/8302
type EventShim = {
  new (...args: any[]): {
    $props: {
      onClick?: (...args: any[]) => void
    }
  }
}

export type WithInstall<T> = T & {
  install(app: App): void
} & EventShim

export function withInstall<T extends Component>(options: T) {
  ;(options as Record<string, unknown>).install = (app: App) => {
    let { name } = options
    if (!name) {
      // @ts-ignore
      const { __file } = options
      if (__file) {
        const r = (__file as string).match(/src\/components\/(.*?)\//)
        if (r) {
          name = r[1]
        }
      }
      throw new Error(`${name} 必须显示给出组件名`)
    }
    if (name) {
      app.component(name, options)
      // app.component(camelize(`-${name}`), options)
    }
  }

  return options as WithInstall<T>
}
