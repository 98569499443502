import request from '.'
import type { TAnyRecord } from '.'

/**
 * 获取当前组织架构树

 * @returns {Promise<any>}
 */
export const getEnterpriseTree = () => {
  return request({
    url: `/core/v1/enterprise/tree`,
    method: 'GET',
  })
}

/**
 * 查看指定企业下的员工列表(组织架构)
 * @param enterpriseId 企业id
 * @param query
 * @param pageNum 当前页码，默认情况下为第一页，页码从1开始
 * @param pageSize 每页的记录数，默认情况下每页十条记录
 * @param paged 是否分页 默认为true
 * @param orderStr 格式为：{name1:direction,name2:direction,...}，例如name:desc,age:asc,createTime
 * @returns {Promise<any>}
 */
export const getEmployeeByEnterpriseId = ({
  enterpriseId,
  query,
  pageNum,
  pageSize,
  paged = false,
  orderStr,
}: TAnyRecord<{ enterpriseId: number }>) => {
  return request({
    url: `/core/v1/employee/list/organization/${enterpriseId}`,
    method: 'GET',
    params: {
      query,
      pageNum,
      pageSize,
      paged,
      orderStr,
    },
  })
}
