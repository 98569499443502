import _MbAddress from './src/index.vue'
import { withInstall } from '../with-install'

const MbAddress = withInstall(_MbAddress)
export default MbAddress
declare module 'vue' {
  export interface GlobalComponents {
    MbAddress: typeof MbAddress
  }
}
