import request, { useLoading } from '.'
import Cos from 'cos-js-sdk-v5'
import dayjs from 'dayjs'
import Compressor from 'compressorjs'
import { showToast } from 'vant'
import pinia, { useUserInfoStore } from '@/pinia'

const { upNumber, downNumber } = useLoading()

const getFileName = (url: string) => {
  const ss = url.split('/')
  return ss[ss.length - 1].replace(/\.(jpe?g|png|webp)$/, '')
}

/**
 * 生成临时令牌
 */
export function createCredential(fileKeyList: string[], bucket: string) {
  return request({
    url: '/qcloud/credential',
    method: 'POST',
    params: {
      bucket,
    },
    data: fileKeyList,
  })
}

// 压缩图片
export const compressImg = (file: File, quality = 0.6) => {
  if (!file) return
  return new Promise<File>((resolve, reject) => {
    new Compressor(file, {
      quality,
      success: (result) => resolve(result as File),
      error: (err) => reject(err),
    })
  })
}

type TUploadFileForCosPrams = {
  fileList: FileList | File[]
  quality?: number
  prefix?: string
  bucket?: string
  hasTimestamp?: boolean
}

export interface IUploadImage {
  url: string
  name: string
}

/**
 * 上传文件到oss
 * @param {{ fileList: Array<File>, quality: Float32Array, prefix: String }} param0
 * @returns
 */
const uploadFileForCos = async ({
  fileList,
  quality = 0.6,
  prefix,
  bucket,
  hasTimestamp = true,
}: TUploadFileForCosPrams) => {
  if (!Array.isArray(fileList) && !fileList?.length) {
    showToast({
      type: 'fail',
      message: 'upload fail, file can not empty',
    })
    return Promise.reject('file can not empty')
  }
  let region = ''
  // cos的bucket
  if (!bucket) {
    if (['dev', 'local'].includes(import.meta.env.VITE_NODE_ENV)) {
      // bucket = 'mb-dev-1312428971'
      bucket = 'devmbmybeauty-1312428971'
      region = 'ap-guangzhou'
    } else if (import.meta.env.VITE_NODE_ENV === 'release') {
      bucket = 'mb-release-1312428971'
      region = 'ap-singapore'
    }
  }

  const compressFiles: File[] = []
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i]
    if (['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)) {
      const result = await compressImg(file, quality)
      compressFiles.push(result as File)
    } else {
      compressFiles.push(file)
    }
  }

  if (!prefix) {
    const userInfoStore = useUserInfoStore(pinia)
    prefix = `/basic-bm-webapp/uploader/${dayjs().format('YYYY-MM-DD')}/${
      userInfoStore.currentOrganizationId
    }/${userInfoStore.code}/`
  }
  const fileKeyList = compressFiles.map(
    (item) => `${prefix}${hasTimestamp ? Date.now() + '_' : ''}${item.name}`
  )
  return createCredential(fileKeyList, bucket!).then((res) => {
    // 生成cos实例
    const cos = new Cos({
      getAuthorization: (_, callback) => {
        callback({
          TmpSecretId: res.data.tmpSecretId,
          TmpSecretKey: res.data.tmpSecretKey,
          SecurityToken: res.data.sessionToken,
          // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
          StartTime: res.data.startTime, // 时间戳，单位秒，如：1580000000
          ExpiredTime: res.data.expiredTime, // 时间戳，单位秒，如：1580000000
        })
      },
    })
    const ps: Promise<IUploadImage>[] = []
    const host = `https://${bucket}.cos.${region}.myqcloud.com/`
    const fullFileKeyList = fileKeyList.map((item) => host + item)
    compressFiles.forEach((file, index) => {
      upNumber()
      const p = new Promise<IUploadImage>((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket! /* 必须 */,
            Region: region /* 存储桶所在地域，必须字段 */,
            Key: fileKeyList[index] /* 必须 */,
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              // 进度条TODO:做loading
              console.log(JSON.stringify(progressData), '---')
            },
          },
          function (err, _) {
            downNumber()
            if (err) {
              reject(err)
            } else {
              // resolve(fullFileKeyList[index])
              resolve({
                url: fullFileKeyList[index],
                name: getFileName(fullFileKeyList[index]),
              })
            }
          }
        )
      })
      ps.push(p)
    })

    downNumber()

    return Promise.all(ps).then((res) => {
      return res
    })
  })
}

export default uploadFileForCos
