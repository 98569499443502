// 和中台有关
import { AxiosResponse } from 'axios'
import request, { TAnyRecord } from '.'

/**
 * 获取员工组织列表
 * @returns {Promise<any>}
 */
export const getEmployeeOrgList = <T>() => {
  return request<any, AxiosResponse<T>>({
    url: `/core/employee/current/list`,
    method: 'GET',
  })
}

/**
 * 切换当前账户上下文的当前组织
 * @param organizationId 切换到的组织ID
 * @returns {Promise<any>}
 */
export const putOrganization = ({
  organizationId,
}: TAnyRecord<{ organizationId: number }>) => {
  return request({
    url: `/core/auth/organization`,
    method: 'PUT',

    data: {
      organizationId,
    },
  })
}
