<script lang="ts">
export default {
  name: 'MbNavBar',
}
</script>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const props = withDefaults(
  defineProps<{
    title?: string
    leftArrow?: boolean
    sticky?: boolean
    clickLeft?: () => any
  }>(),
  {
    title: '',
    leftArrow: false,
    clickLeft: undefined,
    sticky: true,
  }
)
const router = useRouter()
const position = computed(() => (props.sticky ? 'sticky' : 'static'))

const onClickLeft = () => {
  if (props.clickLeft) {
    props.clickLeft()
  } else {
    router.back()
    setTimeout(() => {
      router.replace(window.location.pathname)
    }, 60)
  }
}
</script>

<template>
  <van-nav-bar
    :title="props.title"
    :left-arrow="props.leftArrow"
    v-bind="$attrs"
    @click-left="onClickLeft">
    <template v-if="!title" #title>
      <slot name="title"></slot>
    </template>
    <template #right>
      <slot name="right"></slot>
    </template>
  </van-nav-bar>
</template>

<style lang="scss" scoped>
.van-nav-bar {
  position: v-bind(position);
  top: 0;
  z-index: 99;
}
</style>
