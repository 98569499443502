<script lang="ts">
export default {
  name: 'MbAddress',
}
</script>

<script setup lang="ts">
import { getCities, getCountries, getRegions, getStates } from '@/api/address'
import { printTs } from '@/utils/printTs'
import { AxiosPromise } from 'axios'
import { ref, onMounted } from 'vue'
import { IAddress, IParams } from '../type'

defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
})
const emit = defineEmits(['update:modelValue'])

const cascaderAddress = ref('')
const fieldAddress = ref('')
const showPicker = ref(false)
const options = ref<IAddress[]>([])

onMounted(() => init())

const init = () => {
  getCountries({}).then((res) => {
    options.value = res.data.content.map((item: IAddress) => {
      return {
        ...item,
        text: item.name,
        value: item.id,
        children: [],
      }
    })
  })
}

const getAddress: { [k in number]: (...arg: any) => AxiosPromise<any> } = {
  0: getStates,
  1: getCities,
  2: getRegions,
}

const onChange = ({ value, selectedOptions, tabIndex }: IParams) => {
  if (tabIndex === 3) {
    return
  }
  getAddress[tabIndex]({ parentId: selectedOptions[tabIndex].id }).then(
    (res) => {
      if (res.data.content.length === 0) {
        selectedOptions[tabIndex].children = undefined
        return onFinish({ value, selectedOptions, tabIndex })
      }
      selectedOptions[tabIndex].children = res.data.content.map(
        (i: IAddress) => {
          return {
            ...i,
            value: `${tabIndex}_${i.id}`,
            text: i.name || 'not name',
            children: tabIndex !== 2 ? [] : undefined,
          }
        }
      )
    }
  )
}

const onFinish = ({ selectedOptions }: IParams) => {
  showPicker.value = false
  fieldAddress.value = selectedOptions.map((option) => option.text).join('/')
  emit(
    'update:modelValue',
    selectedOptions.map((i) => i.id)
  )
}
</script>

<template>
  <div class="mb-address">
    <van-field
      v-model="fieldAddress"
      is-link
      readonly
      name="picker"
      label="地区"
      placeholder="点击选择城市"
      @click="showPicker = true" />

    <van-popup v-model:show="showPicker" position="bottom" style="height: 70%">
      <van-cascader
        v-model="cascaderAddress"
        title="地区"
        :options="options"
        @close="showPicker = false"
        @change="onChange"
        @finish="onFinish" />
    </van-popup>
  </div>
</template>
