// import {
//   Button,
//   Tabbar,
//   TabbarItem,
//   Tab,
//   Tabs,
//   NavBar,
//   Col,
//   Row,
//   Badge,
//   Form,
//   Field,
//   Cell,
//   CellGroup,
//   Sticky,
//   Toast,
//   RadioGroup,
//   Radio,
//   Popup,
//   DatetimePicker,
//   DropdownMenu,
//   DropdownItem,
// } from 'vant'
import { setToastDefaultOptions } from 'vant'
import 'vant/es/notify/style'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
setToastDefaultOptions('loading', { forbidClick: true })
setToastDefaultOptions('success', { forbidClick: true })

// import type { App } from 'vue'

// const components = [
//   Button,
//   Tabbar,
//   TabbarItem,
//   Tab,
//   Tabs,
//   NavBar,
//   Col,
//   Row,
//   Badge,
//   Form,
//   Field,
//   Cell,
//   CellGroup,
//   Sticky,
//   RadioGroup,
//   Radio,
//   Popup,
//   DatetimePicker,
//   DropdownMenu,
//   DropdownItem,
// ]

// export const installVant = (app: App) => {
//   components.forEach((component) => {
//     app.use(component)
//   })
// }
