import { getEmployeeOrgList } from '@/api/organization'
import { defineStore } from 'pinia'

export interface IOrganization {
  id: number
  innerName: string
  name: string
  orgCode: string
  solutionIdList: any[]
  sort: number
  logo: string
  countryId: number
  stateId: number
  cityId: number
  regionId: any
  regionCode: any
  address: string
  contactId: any
  contactName: string
  accountKey: string
  contactTelephone: any
  contactMail: any
  expireDate: number
  organizationLimit: number
  employeeLimit: number
  customerLimit: any
  remark: string
  path: number[]
  parentId: any
}

export const useOrganizationStore = defineStore('organization', {
  state: (): IOrganization[] => {
    return []
  },
  actions: {
    async init() {
      return getEmployeeOrgList<IOrganization[]>().then((res) => {
        const data = res.data
        this.$patch(data)
      })
    },
  },
})
