import { createApp } from 'vue'
import type { App as vueApp } from 'vue'
import App from './App.vue'
import 'amfe-flexible/index.min.js'
import 'normalize.css'
import '@/styles/index.scss'
import install from './install'
import VConsole from 'vconsole'
import '@/flutter/init'
import { loadMap } from '@/utils/googleMap'
import { pubsub } from './utils/pubsub'
loadMap()

if (import.meta.env.VITE_NODE_ENV !== 'release') {
  new VConsole()
}

let app: vueApp<Element> | null = null

window.addEventListener('DOMContentLoaded', () => {
  const platform = localStorage.getItem('platform')
  if (platform === 'mybeauty-app') {
    return
  }
  pubsub.publish('initApp', () => {
    console.log('浏览器打开')
  })
})

pubsub.subscribe('initApp', (_, callback) => {
  if (app) return
  app = createApp(App)
  install(app).then(() => {
    app?.mount('#app')
  })

  callback?.call(undefined)
})
