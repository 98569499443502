import { AxiosResponse } from 'axios'
import request from '.'

/**
 * 获取当前登录解决方案资源 应用列表 项目列表
 * @param folderType  PC :PC BM :BM CM :CM {"PC":"PC","BM":"BM","CM":"CM"}
 * @returns {Promise<any>}
 */
export const getSolutionsList = <T>(
  folderType: 'PC' | 'BM' | 'CM' | null = 'BM'
) => {
  return request<any, AxiosResponse<T>>({
    url: `/core/solution/current/${folderType}`,
    method: 'GET',
  })
}
