// @ts-ignore
import * as mqtt from 'mqtt/dist/mqtt'

interface IParams {
  clientId: string
}

type TCallback = (topic: string, msg: any) => void

class MqttTools {
  subscribeCached: Record<string, TCallback> = {}
  clientId?: string
  host?: string
  client?: mqtt.MqttClient

  constructor({ clientId }: IParams) {
    if (MqttTools.instance) return this
    this.clientId = clientId
    // this.host = `ws://api.devmbmybeauty.fun:38083/mqtt`
    this.host = import.meta.env.VITE_APP_MQTT
    this.client = mqtt.connect(this.host, {
      keepalive: 60,
      clientId,
      protocolId: 'MQTT',
      protocolVersion: 4,
      clean: true,
      reconnectPeriod: 1000,
      connectTimeout: 30 * 1000,
      will: {
        topic: 'WillMsg',
        payload: 'Connection Closed abnormally..!',
        qos: 0,
        retain: false,
      },
    })

    this.event()
    MqttTools.instance = this
  }

  static instance: MqttTools | null = null

  disconnect() {
    this.unsubscribe()
    this.client?.end()
    MqttTools.instance = null
    console.log('mqtt disconnected')
  }

  event() {
    this.client?.on('error', (err: any) => {
      console.log('Connection error: ', err)
      this.client?.end()
    })

    this.client?.on('reconnect', () => {
      console.log('Reconnecting...')
    })

    this.client?.on('connect', () => {
      console.log('Client connected:' + this.clientId)
    })

    // Received
    this.client?.on('message', (topic: string, message: any) => {
      // console.log('Received Message: ' + message.toString() + '\nOn topic: ' + topic)
      if (this.subscribeCached[topic]) {
        this.subscribeCached[topic].call(this, topic, message.toString())
      }
    })
  }

  subscribe(topic: string, cb: TCallback) {
    if (!this.subscribeCached[topic]) {
      this.subscribeCached[topic] = cb
      this.client?.subscribe(topic, { qos: 0 })
      console.log(`subscribe topic: ${topic}`)
    }
  }

  unsubscribe() {
    if (this.subscribeCached) {
      const topic = Object.keys(this.subscribeCached).map((key) => key)
      this.client?.unsubscribe(topic)
      console.log(`unsubscribe topic: ${topic}`)
      this.subscribeCached = {}
    }
  }

  publish(topic: string, message: string) {
    this.client?.publish(topic, message)
  }
}

export default MqttTools
