<script lang="ts">
export default {
  name: 'MbUploader',
}
</script>

<script lang="ts" setup>
import { computed, onBeforeMount, onMounted, useAttrs } from 'vue'
import type { UploaderFileListItem } from 'vant'
import uploadFileForCos from '@/api/uploadFile'
import type { IUploadImage } from '@/api/uploadFile'
import { useFlutterStore } from '@/pinia'
import { pubsub } from '@/utils/pubsub'

const flutterStore = useFlutterStore()
const props = defineProps<{
  modelValue: UploaderFileListItem[]
  unicode?: string
  uploaderType?: 'file' | 'media'
}>()
const emit = defineEmits(['update:modelValue'])
const attrs = useAttrs()

const IMAGE_REGEXP = /\.(jpeg|jpg|gif|png|svg|webp|jfif|bmp|dpg|avif)/i
const isImageUrl = (url: string): boolean => IMAGE_REGEXP.test(url)
const uploaderType = computed(() => props.uploaderType || 'media')
const isApp = computed(
  () => flutterStore.globalVariable.platform === 'mybeauty-app'
)
const limit = computed<number>(() => {
  if (attrs.limit) {
    return Number(attrs.limit)
  } else {
    return 9
  }
})
const eventName = computed(() => {
  return props.unicode
    ? `image.uploader.done.${props.unicode}`
    : 'image.uploader.done'
})

onMounted(() => {
  // @ts-ignore
  pubsub.subscribe(eventName.value, (_, [data]) => {
    const list = data.map((i: any) => ({
      url: i.key,
      name: i.fileName,
      ...i,
    }))
    _fileList.value = [..._fileList.value, ...list]
  })
})

onBeforeMount(() => {
  pubsub.unsubscribe(eventName.value)
})

const handleClick = () => {
  const operate = uploaderType.value === 'media' ? 'uploader' : 'uploaderFile'
  pubsub.publish(
    'jsToFlutter',
    JSON.stringify({
      type: 'image',
      operate,
      params: {
        eventName: eventName.value,
        limit: limit.value,
      },
    })
  )
}

const _fileList = computed<UploaderFileListItem[]>({
  get() {
    if (props.modelValue.length > limit.value) {
      return props.modelValue.slice(0, limit.value)
    }
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val.slice(0, 9))
  },
})

const removePicture = (index: number) => {
  _fileList.value.splice(index, 1)
  emit('update:modelValue', _fileList.value)
}

defineExpose({
  upload: () => {
    if (isApp.value) {
      return Promise.resolve(_fileList.value)
    }
    return new Promise<IUploadImage[]>((resolve, reject) => {
      if (_fileList.value.length === 0) {
        resolve([])
      } else {
        const files = _fileList.value.filter((i) => i.file)
        const previews: IUploadImage[] = _fileList.value.filter(
          (i) => !i.file
        ) as unknown as IUploadImage[]
        uploadFileForCos({
          fileList: [...files.map((f) => f.file!)],
        })
          .then((r) => resolve([...r, ...previews]))
          .catch((e) => reject(e))
      }
    })
  },
})
</script>

<template>
  <div class="mb-uploader">
    <!-- 在app端的webview不能直接通过input file选择文件，所以需要与flutter通信，在app端上传完成后再返回链接 -->
    <template v-if="isApp">
      <div class="app-uploader">
        <div v-for="(item, index) in _fileList" :key="index" class="preview">
          <template v-if="isImageUrl(item.url || '')">
            <van-image :src="item.url" width="80px" height="80px" fit="cover" />
          </template>
          <template v-else>
            <div class="preview-file van-ellipsis">
              {{ (item as any).name || item.url }}
            </div>
          </template>
          <div class="close" @click="removePicture(index)">
            <van-icon name="cross" size="12"></van-icon>
          </div>
        </div>
        <div v-if="_fileList.length < limit" class="box" @click="handleClick">
          <van-icon name="photograph"></van-icon>
        </div>
      </div>
    </template>
    <template v-else>
      <van-uploader
        v-model="_fileList"
        multiple
        :max-count="limit"
        v-bind="$attrs"
        v-on="{ ...$attrs }" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.mb-uploader {
  .app-uploader {
    display: flex;
    flex-wrap: wrap;
    .preview {
      position: relative;
      margin-right: 8px;
      margin-bottom: 8px;
      .preview-file {
        padding: 8px;
        width: 80px;
        height: 80px;
        text-align: left;
        line-height: 64px;
        background-color: #f7f8fa;
        border-radius: 4px;
        // text-overflow: ellipsis;
      }
      .close {
        width: 14px;
        height: 14px;
        position: absolute;
        right: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.7);
        border-bottom-left-radius: 7px;
        &:hover {
          cursor: pointer;
        }
      }
      .van-icon-cross {
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
      }
    }
    .box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      margin-right: 8px;
      margin-bottom: 8px;
      background-color: #f7f8fa;
      .van-icon-photograph {
        color: #dcdee0;
        font-size: 24px;
      }
    }
  }
}
</style>
