// flutter下发的数据
import { defineStore } from 'pinia'

/// flutter接收的事件名
type TEvs = 'history.back'

interface IFlutterStore {
  globalVariable: {
    flutterEvent: TEvs[] | null
    platform: 'mybeauty-app' | 'browser' | 'unknown'
    exchange: string | null
  }
}

interface IFlutterGetter {
  flutterEvent: (
    state: IFlutterStore
  ) => IFlutterStore['globalVariable']['flutterEvent']
  [key: string]: any
}

export const useFlutterStore = defineStore<
  'flutterStore',
  IFlutterStore,
  IFlutterGetter
>({
  id: 'flutterStore',
  state: () => {
    return {
      globalVariable: {
        flutterEvent: null,
        platform: 'browser',
        exchange: null,
      },
    }
  },
  getters: {
    flutterEvent: (state) => state.globalVariable.flutterEvent,
  },
})
