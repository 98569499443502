import _MbNavBar from './src/index.vue'
import { withInstall } from '../with-install'

const MbNavBar = withInstall(_MbNavBar)
export default MbNavBar
declare module 'vue' {
  export interface GlobalComponents {
    MbNavBar: typeof MbNavBar
  }
}
