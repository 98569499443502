// 保持pinia的状态

import { useUserInfoStore, useOrganizationStore, useSolutionsStore } from '.'

export const basicInit = async () => {
  const userInfoStore = useUserInfoStore()
  const organizationStore = useOrganizationStore()
  const solutionsStore = useSolutionsStore()
  const ps = [
    userInfoStore.init(),
    organizationStore.init(),
    solutionsStore.init(),
  ]

  return Promise.all(ps)
}
