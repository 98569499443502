import { showToast, closeToast } from 'vant'

export const useLoading = () => {
  let number = 0

  const loading = (message?: string) => {
    showToast({
      type: 'loading',
      duration: 0,
      message,
    })
  }

  const closeLoading = () => {
    closeToast()
  }

  return {
    upNumber: (message?: string) => {
      if (number === 0) {
        loading(message)
      }
      number += 1
    },
    downNumber: () => {
      number -= 1
      if (number === 0) {
        closeLoading()
      }
    },
  }
}
