import _MbRefresh from './src/index.vue'
import { withInstall } from '../with-install'
import { ComponentPublicInstance } from 'vue'

// @ts-ignore
const MbRefresh = withInstall(_MbRefresh)
export default MbRefresh

type MbRefreshExpose<T> = {
  getData: (cb: (data: T[]) => void) => void
}
export type MbRefreshInstance<T = any> = ComponentPublicInstance<
  MbRefreshExpose<T>
>

declare module 'vue' {
  export interface GlobalComponents {
    MbRefresh: typeof _MbRefresh
  }
}
