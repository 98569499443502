import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'

export * from './domainTheme'
export * from './userInfo'
export * from './organization'
export * from './solutions'
export * from './contacts'
export * from './qiankun'
export * from './locales'
export * from './flutter'
export * from './reset'

const pinia = createPinia()
pinia.use(
  createPersistedState({
    key: (id) => {
      // console.log(id, '--------')
      return `__persisted__${id}`
    },
    auto: true,
    beforeRestore(context) {
      if (!Reflect.has(localStorage, `__persisted__${context.store.$id}`)) {
        return context.store.init?.()
      }
    },
  })
)

export default pinia
