<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { computed, onBeforeMount, watch } from 'vue'
import {
  useQiankunStore,
  useDomainThemeStore,
  useLocalesStore,
  useUserInfoStore,
  // useSolutionsStore,
  // useOrganizationStore,
} from '@/pinia'
import { getToken } from './utils/localStorage/useLocalStorage'

const route = useRoute()
const router = useRouter()
const qiankunStore = useQiankunStore()
const domainThemeStore = useDomainThemeStore()
const userInfoStore = useUserInfoStore()
// const solutionsStore = useSolutionsStore()
// const organizationStore = useOrganizationStore()
const localesStore = useLocalesStore()

qiankunStore.init()
domainThemeStore.init().then(() => {
  localesStore.updateLanguage(
    userInfoStore.language || domainThemeStore.language
  )
})

const appCodes = computed(() =>
  qiankunStore.apps.map((item) => `/${item.appCode}`)
)

const appCode = computed(() => {
  return appCodes.value.find((item) => route.path.startsWith(`${item}/`)) || ''
})

watch(
  appCode,
  (val) => {
    console.log('APPPPPPPP')
    if (val) {
      qiankunStore.loadMicroApp(val.replace('/', ''))
    } else {
      qiankunStore.unMount()
    }
  },
  {
    immediate: true,
  }
)

const init = () => {
  if (localStorage.getItem('hasOrganization') === 'false') {
    router.push({
      path: '/login',
      query: {
        active: 'enterEnterprise',
      },
    })
  } else if (getToken()) {
    // userInfoStore.init()
    // solutionsStore.init()
    // organizationStore.init()
  }
}

onBeforeMount(init)
</script>

<template>
  <div v-show="appCode" id="subapp"></div>
  <router-view></router-view>
</template>
