import request from '.'
import type { TAnyRecord } from '.'

type TLoginType = 'PASSWORD' | 'CAPTCHA'
type TLoginScope = 'BUSINESS' | 'CLIENT'

// 登录
export const login = ({
  loginKey,
  loginValue,
  type = 'PASSWORD',
  scope = 'BUSINESS',
  cc = 60,
  iso_code,
}: TAnyRecord<{ scope?: TLoginScope; type?: TLoginType }>) => {
  return request({
    url: '/core/auth/login',
    method: 'post',
    needToken: false,
    data: {
      loginKey,
      type,
      scope,
      cc,
      iso_code,
      loginValue,
      clientId: 'core',
      device: 'MOBILE',
    },
  })
}

// 获取图片验证码
export const getImgCode = () => {
  return request({
    url: '/core/auth/captcha',
    needToken: false,
    originResponse: true,
    responseType: 'blob',
  })
}

// 发送验证码,需要图片验证码返回来的token
export const sendMessage = ({
  loginKey,
  captchaToken,
  captchaCode,
  cc = 86,
  iso_code,
}: TAnyRecord) => {
  return request({
    url: '/core/auth/captcha',
    needToken: false,
    method: 'post',
    data: {
      loginKey,
      cc,
      iso_code,
      captchaToken,
      captchaCode,
    },
  })
}

// 没有设置过密码，设置密码
export const onceSettingPassword = ({
  password,
  repeatPassword,
}: TAnyRecord) => {
  return request({
    url: '/core/account/password',
    method: 'post',
    data: {
      password,
      repeatPassword,
    },
  })
}

// 退出登录
export const logoutEnd = () => {
  return request({
    url: '/core/auth/logout',
    method: 'post',
  })
}
